import React from "react";
import Layout from "../components/layout/Layout";

import "./index.css";
import CoursesByCategory from "../components/lists/CoursesByCategory";
import SEO from "../components/seo/SEO";

export default function Index() {
  return (
    <>
      <SEO
        title="Tietoliikennekursseja yrityksille"
        description="Tutustu monipuoliseen kurssitarjontaamme ja ota yhteyttä."
      />
      <Layout>
        <div
          className="mt-2 px-4 py-2 bg-indigo-800 text-white text-center rounded-lg shadow-md"
          data-nosnippet
        >
          Toteutamme yrityskohtaiset kurssit etänä tai paikan päällä.
          Räätälöimme kursseille harjoitukset juuri sinun yrityksesi tarpeet
          huomioiden. Pyydä tarjous!
        </div>

        <div className="-mx-8 px-8 py-5 bg-yellow-100">
          <CoursesByCategory categoryName="Linux" />
          <CoursesByCategory categoryName="Tietoliikenne" />
          <CoursesByCategory categoryName="XML" />
        </div>
      </Layout>
    </>
  );
}
